<template>
  <div>
    <header-banner
      banner-img="https://static.moegoat.com/images/banner/banner-4.png"
      title="会员中心"
      :sub-title="currentTab"
    />
    <UserNav :index="0" />
    <v-container class="d-flex justify-center pt-5">
      <v-card
        :width="$vuetify.breakpoint.smAndUp ? '50%' : '100%'"
        height="200px"
        v-if="!user_vip"
      >
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="grey"></v-progress-circular>
        </v-row>
      </v-card>
      <v-card :width="$vuetify.breakpoint.smAndUp ? '50%' : '100%'" v-else>
        <v-list flat subheader class="pb-0">
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>昵称：{{ user_name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-email</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                邮箱：{{ user_email }}
                <v-chip class="ml-2" v-if="!email_verified">未激活</v-chip>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                v-if="!email_verified"
                text
                @click="handleSendVerifyLink"
                :loading="loading_sendVerifyLink"
                :disabled="loading_sendVerifyLink || send_disabled"
              >
                <v-icon left>mdi-email-send-outline</v-icon>
                {{ send_text }}
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-diamond</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>会员类型：{{ user_vip }} <v-chip v-if="user_svip === 1" color="#fb7299" small dark class="ml-2">🚌 巴士会</v-chip></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item v-if="expiry_date">
            <v-list-item-icon>
              <v-icon>mdi-calendar-clock</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>到期时间：{{ expiry_date }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-bus-articulated-front</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>尊享巴士会SVIP：{{ user_svip === 1 ? `${svip_expiry_date} 到期` : '未开通' }}
                <v-btn
                    style="position: relative;top: -2px;left: -5px"
                    v-if="user_svip !== 1"
                    small
                    icon
                    fab
                    to="/order?plan=0&svip=1"
                >
                  <v-icon>
                    mdi-exit-to-app
                  </v-icon>
                </v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>

          <v-list-item>
            <v-list-item-content>
              <v-btn
                height="40"
                block
                text
                @click="changePassword_dialog = true"
                >修改密码</v-btn
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-btn block color="#fb7299" dark @click="openUrl('http://loix.cc/')"
          >🔗 点这里获取LOIBUS地址发布页</v-btn
        >
      </v-card>
    </v-container>

    <v-dialog width="500" v-model="changePassword_dialog">
      <v-card width="500" class="pa-3">
        <v-card-title>修改密码</v-card-title>
        <v-card-text class="mt-5">
          <v-form
            ref="changePasswordForm"
            v-model="changePasswordValid"
            lazy-validation
          >
            <v-text-field
              v-model="changePasswordFormData.inputPassword"
              :rules="passwordRules"
              type="password"
              label="当前密码"
              autofocus
              required
            ></v-text-field>
            <v-text-field
              class="mt-5"
              v-model="changePasswordFormData.newPassword"
              :rules="passwordRules"
              type="password"
              label="新密码"
              required
            ></v-text-field>
            <v-text-field
              class="mt-5"
              v-model="changePasswordFormData.newPassword_confirmation"
              :rules="passwordConfirmationRule"
              @keyup.enter="handleChangePassword"
              type="password"
              label="重新输入新密码"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-4">
          <v-btn color="primary" text @click="changePassword_dialog = false"
            >返回</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="white--text mr-1"
            color="primary"
            :loading="loading_changePassword"
            :disabled="loading_changePassword"
            @click="handleChangePassword"
            height="40px"
            width="130px"
            >修改我的密码</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import HeaderBanner from "@/components/HeaderBanner.vue";
import UserNav from "@/components/UserNav.vue";
import { vipList } from "@/config.js";
import { changePassword, send_verifyLink } from "@/api/user.js";
import msg from "@/utils/snackbar.js"; //消息提示组件

export default {
  name: "Popular",
  components: {
    HeaderBanner,
    UserNav,
  },
  data: () => ({
    currentTab: "我的资料",
    user_name: "",
    user_email: "",
    user_vip: "",
    email_verified: false,
    expiry_date: "",
    user_svip:0,
    svip_expiry_date:"",


    loading_sendVerifyLink: false,

    //修改密码
    changePassword_dialog: false,
    changePasswordValid: false,
    loading_changePassword: false,

    timer_verificationEmail: null,
    timer_verificationEmail_count: 60,
    send_disabled: false,
    send_text: "发送验证邮件",

    passwordRules: [
      (v) => !!v || "请输入密码",
      (v) => (v && v.length >= 8 && v.length <= 20) || "密码长度为8-20个字符",
      (v) =>
        /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)^\S{8,20}$/.test(
          v
        ) || "密码必须包含字母、数字、符号种至少2种",
    ],
    changePasswordFormData: {
      inputPassword: "",
      newPassword: "",
      newPassword_confirmation: "",
    },
  }),
  created() {
    this.$store.dispatch("getInfo").then((data) => {
      this.user_name = data.username;
      this.user_email = data.email;
      this.expiry_date = data.expiry_at;
      this.email_verified = data.email_verified;
      this.user_vip = vipList[data.vip_id].level;
      this.user_svip = data.svip;
      this.svip_expiry_date = data.svip_expiry_at
    });
  },
  methods: {
    openUrl(url) {
      window.open(url, "_blank");
    },
    async handleChangePassword() {
      if (!this.$refs.changePasswordForm.validate()) {
        return msg("请正确填写并核对新密码", "error", "alert-circle", false);
      }
      this.loading_changePassword = true;

      try {
        let response = await changePassword({
          inputPassword: this.changePasswordFormData.inputPassword,
          newPassword: this.changePasswordFormData.newPassword,
        });
        msg(response.data, "success", "check", false);
        this.changePassword_dialog = false;
      } catch (error) {
        if (error.err_msg) {
          this.loading_changePassword = false;
          return msg(error.err_msg, "error", "alert-circle", false);
        }
        msg("请求异常", "error", "alert-circle", false);
      }
      this.loading_changePassword = false;
    },
    async handleSendVerifyLink() {
      this.loading_sendVerifyLink = true;
      try {
        let response = await send_verifyLink(this.$store.getters.token);

        msg(response.data, "success", "send-check", false);

        //发送后禁用
        this.send_disabled = true;
        this.timer_verificationEmail_count = 60;
        this.send_text = `${this.timer_verificationEmail_count}秒后发送`;
        // 限制发送间隔
        this.timer_verificationEmail = setInterval(() => {
          this.timer_verificationEmail_count--;
          this.send_text = `${this.timer_verificationEmail_count}秒后发送`;
          if (this.timer_verificationEmail_count <= 0) {
            clearInterval(this.timer_verificationEmail);
            this.timer_verificationEmail = null;
            this.send_disabled = false;
            this.send_text = "发送验证邮件";
          }
        }, 1000);
      } catch (error) {
        msg("发送验证邮件失败", "error", "alert-circle", false);
      }

      this.loading_sendVerifyLink = false;
    },
  },
  computed: {
    passwordConfirmationRule() {
      return [
        (v) => !!v || "请重新输入密码",
        (v) =>
          v === this.changePasswordFormData.newPassword || "两次输入密码不一致",
      ];
    },
  },
};
</script>

