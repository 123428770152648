<template>
  <v-tabs class="SecondaryNav" v-model="page_index" slider-color="loi" color="loi" fixed-tabs>
    <v-tab @click="to({path:'/account'})">👨‍💼 我的资料</v-tab>
    <v-tab @click="to({path:'/my_likes'})">❤️ 我的喜欢</v-tab>
    <v-tab @click="to({path:'/my_downloadRecord'})">🕒 下载记录</v-tab>
  </v-tabs>
</template>

<script>
export default {
  props: ["index"],
  data() {
    return {
      page_index: this.index
    };
  },
  methods: {
    to(route) {
      this.$router.push(route.path);
    }
  }
};
</script>
<style scoped>
.SecondaryNav {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  width: 100%;
}
</style>